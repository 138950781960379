import React from "react";

interface Props {
  from_languages: Language[];
  to_languages: Language[];
  subjects: Translators.Subject[];
  search: string;
  updateState: (nextState: Partial<Translators.FilterState>) => void;
}

export default function Filters(props: Props) {
  const { from_languages, to_languages, subjects, search, updateState } = props;

  return (
    <div className="filters">
      <div className="selects">
        <div className="field from-language">
          <label>Oversetter fra</label>
          <select
            name="fromLanguage"
            onChange={(e) =>
              updateState({ fromLanguage: parseInt(e.target.value) })
            }>
            <option value="">Alle språk</option>
            {from_languages.map((l) => (
              <option key={`from-language-${l.id}`} value={l.id}>
                {l.name}
              </option>
            ))}
          </select>
        </div>
        <div className="field to-language">
          <label>Oversetter til</label>
          <select
            name="toLanguage"
            onChange={(e) =>
              updateState({ toLanguage: parseInt(e.target.value) })
            }>
            <option value="">Alle språk</option>
            {to_languages.map((l) => (
              <option key={`to-language-${l.id}`} value={l.id}>
                {l.name}
              </option>
            ))}
          </select>
        </div>
        <div className="field subject">
          <label>Emne</label>
          <select
            name="subject"
            onChange={(e) =>
              updateState({ subject: parseInt(e.target.value) })
            }>
            <option value="">Alle emner</option>
            {subjects.map((s) => (
              <option key={`subject-${s.id}`} value={s.id}>
                {s.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <input
        className="searchFilter"
        name="search"
        type="text"
        placeholder="Søk"
        value={search}
        onChange={(e) => updateState({ search: e.target.value })}
      />
    </div>
  );
}
