import React, { useState } from "react";
import Item from "./Navigation/Item";

interface Props {
  currentPage: Navigation.Page;
  currentRoot: Navigation.Page;
  pages: Navigation.Page[];
  loginLinks: Navigation.Link[];
}

export default function Navigation(props: Props) {
  const [expanded, setExpanded] = useState<Navigation.Page | null>(null);
  const [pushHeight, setPushHeight] = useState(0);

  const isCurrentRoot = (page: Navigation.Page) => {
    return props.currentRoot && props.currentRoot.id === page.id;
  };

  const isExpanded = (page: Navigation.Page) => {
    return expanded && expanded.id === page.id;
  };

  const pushStyle = {
    height: pushHeight
  };

  const toggleExpanded = (page: Navigation.Page) => {
    if (isExpanded(page)) {
      setExpanded(null);
      setPushHeight(0);
    } else {
      setExpanded(page);
    }
  };

  return (
    <div className="navigation">
      <ul>
        {props.pages.map((p) => (
          <Item
            key={p.id}
            page={p}
            current={isCurrentRoot(p)}
            currentPage={props.currentPage}
            expanded={isExpanded(p)}
            toggleExpanded={toggleExpanded}
            setHeight={setPushHeight}
          />
        ))}
        {props.loginLinks.map((l) => (
          <li key={l.url} className="top-level login">
            <a href={l.url}>{l.name}</a>
          </li>
        ))}
      </ul>
      <div
        className={"push " + (expanded ? "expanded" : "")}
        style={pushStyle}></div>
    </div>
  );
}
