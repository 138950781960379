// Entry point for the build script in your package.json

// Rails
import Rails from "@rails/ujs";
Rails.start();

// Ready handler
import readyHandler from "./lib/readyHandler";
readyHandler.start();

// Responsive embeds
import responsiveEmbeds from "./lib/responsiveEmbeds";
responsiveEmbeds();

// React
import { FC } from "react";
import * as Components from "./components";
import ReactRailsUJS from "react_ujs";
ReactRailsUJS.getConstructor = (className: string) =>
  Components[className] as FC;

import "./features/expandableBlocks";
import "./features/nav";
import "./features/popup";
import "./features/stipendTabs";
