import React, { useState } from "react";

import Book from "./Books/Book";

interface Props {
  books: Books.Book[];
  subjects: Books.Subject[];
  literature_groups: Books.LiteratureGroup[];
}

function fuzzymatch(str: string, query: string) {
  const pattern = query
    .toLowerCase()
    .split(" ")
    .reduce((a, b) => a + ".*" + b);
  return new RegExp(pattern).test(str.toLowerCase());
}

function matchesSearch(b: Books.Book, query: string) {
  const str = [
    b.title,
    b.author,
    b.translator,
    b.publisher,
    b.subject.name
  ].join(" ");
  return fuzzymatch(str, query);
}

export default function Books(props: Props) {
  const { subjects, literature_groups } = props;

  const perPage = 50;
  const [state, setState] = useState<Books.FilterState>({
    page: 1,
    search: "",
    subject: null,
    literature_group: null
  });

  const updateState = (nextState: Partial<Books.FilterState>) => {
    setState({ ...state, ...nextState });
  };

  const filteredBooks = props.books.filter((b: Books.Book) => {
    if (state.search && !matchesSearch(b, state.search)) {
      return false;
    }

    if (state.subject && state.subject != b.subject.id) {
      return false;
    }

    if (
      state.literature_group &&
      state.literature_group != b.literature_group.id
    ) {
      return false;
    }

    return true;
  });

  const gotoPage = (evt: React.MouseEvent, page: number) => {
    updateState({ page: page });
  };

  const paginate = (books: Books.Book[]) => {
    const offset = (state.page - 1) * perPage;
    return books.slice(offset, offset + perPage);
  };

  const renderFilters = () => {
    return (
      <div className="filters">
        <div className="selects">
          <div className="field subject">
            <label>Emne</label>
            <select
              name="subject"
              onChange={(e) =>
                updateState({ subject: parseInt(e.target.value), page: 1 })
              }>
              <option value="">Alle emner</option>
              {subjects.map((s) => (
                <option key={`subject-${s.id}`} value={s.id}>
                  {s.name}
                </option>
              ))}
            </select>
          </div>
          <div className="field literature-group">
            <label>Literaturgruppe</label>
            <select
              name="literature-group"
              onChange={(e) =>
                updateState({
                  literature_group: parseInt(e.target.value),
                  page: 1
                })
              }>
              <option value="">Alle grupper</option>
              {literature_groups.map((s) => (
                <option key={`literature-group-${s.id}`} value={s.id}>
                  {s.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <input
          className="searchFilter"
          name="search"
          type="text"
          placeholder="Søk"
          value={state.search}
          onChange={(e) => updateState({ search: e.target.value, page: 1 })}
        />
      </div>
    );
  };

  const renderPagination = (books: Books.Book[]) => {
    const page = state.page;
    const pages = Math.ceil(books.length / perPage);

    if (books.length <= perPage) {
      return "";
    }

    return (
      <div className="pagination">
        {page > 1 && (
          <a
            href="#liste"
            className="previous_page"
            onClick={(e) => gotoPage(e, page - 1)}>
            Forrige side
          </a>
        )}
        {page < pages && (
          <a
            href="#liste"
            className="next_page"
            onClick={(e) => gotoPage(e, page + 1)}>
            Neste side
          </a>
        )}
      </div>
    );
  };

  const paginatedBooks = paginate(filteredBooks);

  return (
    <section className="books-index filterable-index">
      <a id="liste" />
      {renderFilters()}
      <div className="list" aria-live="polite">
        {paginatedBooks.length > 0 &&
          paginatedBooks.map((b) => <Book book={b} key={`book-row-${b.id}`} />)}
        {paginatedBooks.length == 0 && (
          <div className="no-match">
            Fant ingen bøker som matcher dine kriterier.
          </div>
        )}
      </div>
      {renderPagination(filteredBooks)}
    </section>
  );
}
