import React, { useState } from "react";

import Filters from "./Translators/Filters";
import Translator from "./Translators/Translator";

interface Props {
  translators: Translators.Translator[];
  from_languages: Language[];
  to_languages: Language[];
  subjects: Translators.Subject[];
}

function fuzzymatch(str: string, query: string) {
  const pattern = query
    .toLowerCase()
    .split(" ")
    .reduce((a, b) => a + ".*" + b);
  return new RegExp(pattern).test(str.toLowerCase());
}

function matchesSearch(t: Translators.Translator, query: string) {
  const str = [
    t.name,
    t.bio,
    t.from_languages.map((l) => l.name).join(" "),
    t.to_languages.map((l) => l.name).join(" "),
    t.subjects.map((s) => s.name).join(" ")
  ].join(" ");
  return fuzzymatch(str, query);
}

function hasSubject(t: Translators.Translator, subject: number) {
  return t.subjects.filter((s) => s.id == subject).length > 0;
}

function hasFromLanguage(t: Translators.Translator, language: number) {
  return t.from_languages.filter((l) => l.id == language).length > 0;
}

function hasToLanguage(t: Translators.Translator, language: number) {
  return t.to_languages.filter((l) => l.id == language).length > 0;
}

export default function Translators(props: Props) {
  const { translators } = props;

  const [state, setState] = useState<Translators.FilterState>({
    search: "",
    fromLanguage: null,
    toLanguage: null,
    subject: null
  });

  const updateState = (nextState: Partial<Translators.FilterState>) => {
    setState({ ...state, ...nextState });
  };

  const filteredTranslators = translators.filter(
    (t: Translators.Translator) => {
      if (state.search && !matchesSearch(t, state.search)) {
        return false;
      }

      if (state.subject && !hasSubject(t, state.subject)) {
        return false;
      }

      if (state.fromLanguage && !hasFromLanguage(t, state.fromLanguage)) {
        return false;
      }

      if (state.toLanguage && !hasToLanguage(t, state.toLanguage)) {
        return false;
      }

      return true;
    }
  );

  return (
    <section className="translators-index filterable-index">
      <Filters {...props} search={state.search} updateState={updateState} />
      <div className="list" aria-live="polite">
        {filteredTranslators.length > 0 &&
          filteredTranslators.map((t) => (
            <Translator translator={t} key={`translator-row-${t.id}`} />
          ))}
        {filteredTranslators.length == 0 && (
          <div className="no-match">
            Fant ingen oversettere som matcher dine kriterier.
          </div>
        )}
      </div>
    </section>
  );
}
