import readyHandler from "../lib/readyHandler";

readyHandler.ready(function () {
  const tabs: HTMLLinkElement[] = Array.from(
    document.querySelectorAll(".stipend-tabs a")
  );
  const stipend = document.querySelector(".stipend");

  if (tabs.length > 0) {
    const showTab = (name: string) => {
      tabs.forEach(function (t: HTMLLinkElement) {
        stipend.classList.remove("show-" + t.dataset.tab);
        if (t.dataset.tab == name) {
          t.classList.add("current");
        } else {
          t.classList.remove("current");
        }
      });
      stipend.classList.add("show-" + name);
    };

    tabs.forEach(function (a: HTMLLinkElement) {
      a.addEventListener("click", function (evt: Event) {
        evt.preventDefault();
        showTab(a.dataset.tab);
      });
    });

    const firstTab = tabs[0];

    if (firstTab && "dataset" in firstTab && "tab" in firstTab.dataset) {
      showTab(firstTab.dataset.tab);
    }
  }
});
