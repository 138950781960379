import React, { useState } from "react";

import AddLanguageForm from "./LanguageSelection/AddLanguageForm";

interface Props {
  attribute: string;
  languages: Language[];
  selected: Language[];
}

export default function LanguageSelection(props: Props) {
  const { attribute, languages } = props;

  const [selected, setSelected] = useState(props.selected);

  const findLanguage = (id: number) => {
    return languages.filter((l) => l.id == id)[0];
  };

  const selectLanguage = (id: number) => {
    setSelected([...selected, findLanguage(id)]);
  };

  const removeLanguage = (language: Language) => {
    setSelected(selected.filter((l) => l != language));
  };

  const languageCandidates = languages.filter(
    (l) => selected.indexOf(l) === -1
  );

  return (
    <div className="language-selection field">
      {selected.length > 0 && (
        <ul className="languages">
          {selected.map((l) => (
            <li key={`selected-language-${l.id}`}>
              <input
                type="hidden"
                name={"translator[" + attribute + "][]"}
                value={l.id}
              />
              <a
                href="#"
                className="remove-link"
                onClick={(evt) => {
                  evt.preventDefault();
                  removeLanguage(l);
                }}>
                Fjern
              </a>
              {l.name}
            </li>
          ))}
        </ul>
      )}
      <AddLanguageForm
        attribute={attribute}
        languages={languageCandidates}
        selectLanguage={selectLanguage}
      />
    </div>
  );
}
