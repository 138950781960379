import React, { createRef, useEffect, useState } from "react";

interface Props {
  currentPage: Navigation.Page;
  id: string;
  pages: Navigation.Page[];
  setHeight: (height: number) => void;
}

export default function Subnavigation(props: Props) {
  const { id, pages, currentPage, setHeight } = props;

  const [transition, setTransition] = useState(true);
  const elemRef = createRef<HTMLUListElement>();

  useEffect(() => {
    const handleResize = () => {
      if (elemRef.current) {
        setHeight(elemRef.current.clientHeight);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    setTransition(false);
    return () => window.removeEventListener("resize", handleResize);
  }, [elemRef, setHeight]);

  return (
    <ul
      id={id}
      className={"subnavigation " + (transition && "transition")}
      ref={elemRef}>
      {pages.map((p) => {
        return (
          <li key={p.id}>
            <a
              href={p.url}
              className={
                currentPage && currentPage.id === p.id ? "current" : ""
              }>
              {p.name}
            </a>
          </li>
        );
      })}
    </ul>
  );
}
