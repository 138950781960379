import readyHandler from "../lib/readyHandler";

readyHandler.ready(function () {
  const mobileToggle = document.querySelector(".mobile-toggle a");

  // Toggle mobile
  mobileToggle.addEventListener("click", function (evt) {
    evt.preventDefault();
    document.body.classList.toggle("mobile-nav");

    if (document.body.classList.contains("mobile-nav")) {
      mobileToggle.setAttribute("aria-expanded", "true");
    } else {
      mobileToggle.setAttribute("aria-expanded", "false");
    }
  });
});
