import React from "react";

interface Props {
  book: Books.Book;
}

export default function Book(props: Props) {
  const book = props.book;
  return (
    <a className="book entry" href={book.url}>
      <div className="image-container">
        <div className="image book-cover">
          {book.image && <img src={book.image} width="140" />}
        </div>
      </div>
      <div className="text">
        <h4 className="title">{book.title}</h4>
        <h4 className="author-name">{book.author}</h4>
        {book.translator && (
          <h4 className="translator">Oversatt av {book.translator}</h4>
        )}
        <p>
          {book.publisher && book.publisher + ", "}
          {book.year}
        </p>
      </div>
    </a>
  );
}
