import React, { useState } from "react";

interface Props {
  attribute: string;
  languages: Language[];
  selectLanguage: (id: number) => void;
}

export default function AddLanguageForm(props: Props) {
  const { attribute, languages, selectLanguage } = props;

  const [selected, setSelected] = useState("");

  const performSelection = () => {
    if (selected) {
      selectLanguage(parseInt(selected));
      setSelected("");
    }
  };

  return (
    <div className="add-language">
      <label htmlFor={`add-${attribute}`}>Språk</label>
      <span className="select-wrapper">
        <select
          id={`add-${attribute}`}
          name={`add-${attribute}`}
          value={selected}
          onChange={(e) => setSelected(e.target.value)}>
          <option value="">Velg språk</option>
          {languages.map((l) => (
            <option key={`language-${l.id}`} value={l.id}>
              {l.name}
            </option>
          ))}
        </select>
      </span>
      <button type="button" onClick={performSelection}>
        Legg til
      </button>
    </div>
  );
}
