import React from "react";
import Subnavigation from "./Subnavigation";

interface Props {
  page: Navigation.Page;
  currentPage: Navigation.Page;
  current: boolean;
  expanded: boolean;
  toggleExpanded: (page: Navigation.Page) => void;
  setHeight: (height: number) => void;
}

export default function Item(props: Props) {
  const { page, currentPage, current, expanded, setHeight, toggleExpanded } =
    props;

  const isExpandable = page.pages ? true : false;

  const handleClick = (evt: React.MouseEvent) => {
    if (isExpandable) {
      evt.preventDefault();
      toggleExpanded(page);
    }
  };

  const classNames = [];
  if (isExpandable) {
    classNames.push("expandable");
  }
  if (current) {
    classNames.push("current");
  }
  if (expanded) {
    classNames.push("expanded");
  }

  const subnavId = `subnav-${page.id}`;

  return (
    <li className="top-level">
      <a
        className={classNames.join(" ")}
        href={page.url}
        onClick={handleClick}
        aria-expanded={expanded}
        aria-controls={subnavId}>
        {page.name}
      </a>
      {expanded && (
        <Subnavigation
          id={subnavId}
          pages={page.pages}
          currentPage={currentPage}
          setHeight={setHeight}
        />
      )}
    </li>
  );
}
