import React from "react";

interface Props {
  translator: Translators.Translator;
}

export default function Translator(props: Props) {
  const { translator } = props;

  const languageNames = translator.from_languages.map((l) => l.name);

  const subjectNames = translator.subjects.map((l) => l.name);

  return (
    <a className="translator entry" href={translator.url}>
      <div className="image-container">
        <div className="image">
          {translator.image && (
            <img src={translator.image} width="140" height="210" />
          )}
        </div>
      </div>
      <div className="text">
        <h4>{translator.name}</h4>
        <p>Oversetter fra: {languageNames.join(" | ")}</p>
        <p>Emner: {subjectNames.join(" | ")}</p>
      </div>
    </a>
  );
}
