import readyHandler from "../lib/readyHandler";

readyHandler.ready(function () {
  const links = document.querySelectorAll("a.popup");

  links.forEach(function (link: HTMLLinkElement) {
    link.addEventListener("click", function (evt: Event) {
      evt.preventDefault();
      const url = link.href;
      const popup = window.open(url, "_popup", "height=250,width=600");
      if (window.focus) {
        popup.focus();
      }
      return false;
    });
  });
});
