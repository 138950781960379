import React from "react";

import VtvBook from "./VtvBooks/VtvBook";

interface Props {
  books: VtvBooks.Book[];
}

export default function VtvBooks(props: Props) {
  const { books } = props;
  return (
    <div className="books-list">
      {books.map((book) => (
        <VtvBook key={book.id} book={book} />
      ))}
    </div>
  );
}
