import React from "react";

interface Props {
  book: VtvBooks.Book;
}

export default function VtvBook(props: Props) {
  const { book } = props;

  const bgStyle = {
    backgroundImage: `url(${book.background})`
  };

  return (
    <article className="vtv-book" style={bgStyle}>
      <div className="quote">{book.quote}</div>
      <div className="info">
        <div className="thumbnail">
          <img src={book.thumbnail} alt={book.title} />
        </div>
        <div className="author">{book.author}</div>
        <div className="title">{book.title}</div>
        <div className="publisher">
          {book.publisher}
          {book.year && <span className="year">, {book.year}</span>}
        </div>
        <div className="buy">
          <a href={book.url}>Les mer om boken</a>
        </div>
      </div>
    </article>
  );
}
